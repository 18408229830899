<script setup lang="ts">
import Generic from '~/layouts/generic.vue'
import Header from '~/components/Layout/Header.vue'
import Banner from '~/components/Layout/Banner.vue'
import LazyLeadDialog from '~/components/Lead/LeadDialog.vue';
import LazyNewsletterDialog from '~/components/Newsletter/NewsletterDialog.vue';
import { useBannerStore } from '~/stores/useBannerStore';

const { isBannerVisible } = useBannerStore()
const { loadBrandList } = useBrandStore()
const { setCategory } = useCategoryStore()
const { loadNewRateList } = useNewRatesStore()
const toast = useSharedToast()

useAsyncData('category', async () => setCategory())
useAsyncData('brand-list', async () => loadBrandList(), { server: false })
useAsyncData('new-rate-list', async () => loadNewRateList(), { server: false })
</script>

<template>
  <Generic>
    <Header />
    <Banner v-if="isBannerVisible" />
    <LazyLeadDialog />
    <LazyNewsletterDialog />
    <Toast position="bottom-center" group="bc" />
    <div :class="`${isBannerVisible ? 'mt-28' : 'mt-20'} w-full bg-white ${isBannerVisible ? 'md:mt-28' : 'md:mt-28'}`">
      <router-view />
    </div>
  </Generic>
</template>